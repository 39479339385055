import React from 'react'
import {MainLayout} from '../../layout'
import {ForgotPasswordForm} from '../../components/auth'
import {sendPasswordResetCode} from '../../backend'
import {navigate} from '../../utils'

export default function ForgotPassword() {
  const handleSendCode = async ({phone}) => {
    await sendPasswordResetCode({phone})
    navigate(`/auth/reset-password?phone=${encodeURIComponent(phone)}`)
  }

  return (
    <MainLayout>
      <ForgotPasswordForm onSendCode={handleSendCode}/>
    </MainLayout>
  )
}
